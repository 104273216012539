<template>
  <div class="main-content">
    <transition name="fade">
    </transition>
    <div class="overflow-x-auto lg:overflow-x-hidden">

    </div>

    <div class="p-8">
      <div class="mb-4">
        <label for="doc" class="block text-sm font-bold mb-2">Documento: CNPJ ou CPF</label>
        <input type="text" id="doc" v-model="doc" :placeholder="maskPlaceholder" @input="applyMask"
          class="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" />
      </div>
      <div class="mb-4">
        <label for="month" class="block text-sm font-bold mb-2">Mês</label>
        <select id="month" v-model="selectedMonth"
          class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
          <option v-for="month in availableMonths" :key="month" :value="month">{{ month }}</option>
        </select>
      </div>
      <div class="mb-4">
        <label for="year" class="block text-sm font-bold mb-2">Ano</label>
        <select id="year" v-model="selectedYear"
          class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
          <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
        </select>
      </div>
      <div>
        <button @click="consultSCR" :disabled="loading"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          {{ (!loading ? 'Consultar SCR' : 'Gerando dados SCR') }}
          <span v-if="loading" class="ml-2 animate-spin fa fa-spinner fa-spin text-2xl text-secondary"></span>
        </button>
      </div>


      <hr class="mt-10 mb-10" />

      <div class="mb-4">
        <label for="filtroDocumento" class="block text-sm font-bold mb-2">Filtrar por documento</label>
        <input style="width: 80%;" id="filtroDocumento" type="text" v-model="filtroDocumento" placeholder=""
          v-on:keyup.enter="getConsultas"
          class="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline" />

        <button @click="getConsultas"
          class="ml-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Filtrar</button>
      </div>
      <vuetable ref="vuetable" :fields="header" :api-mode="false" :data-manager="dataManager"
        :css="{ tableClass: 'proposals' }"
        :no-data-template="loadingConsultas ? 'Carregando consultas...' : 'Nenhuma consulta encontrada'">
        <template v-slot:data="props">
          <div class="flex items-center justify-center">
            <p>{{ formatDateTime(props.rowData.dateTime) }}</p>
          </div>
        </template>
        <template v-slot:document="props">
          <div class="flex items-center justify-center">
            <p>{{ formatDocument(props.rowData.document) }}</p>
          </div>
        </template>

        <template v-slot:database="props">
          <div class="flex items-center justify-center">
            <p>{{ `${props.rowData.month}/${props.rowData.year}` }}</p>
          </div>
        </template>

        <template v-slot:link="props">
          <div class="flex items-center justify-center">
            <p><button @click="visualizarSCR(props.rowData._id)" :loading="baixandoConsultas.includes(props.rowData._id)"
                :disabled="baixandoConsultas.includes(props.rowData._id)"
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                {{ (!baixandoConsultas.includes(props.rowData._id) ? 'Visualizar' : 'Carregando ') }}
                <span v-if="baixandoConsultas.includes(props.rowData._id)"
                  class="ml-2 animate-spin fa fa-spinner fa-spin text-2xl text-secondary"></span>
              </button></p>
          </div>
        </template>
      </vuetable>
      <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"
        :css="paginationCss"></vuetable-pagination>
      <p v-if="!loadingConsultas && pagination.total > 0" class="text-right">{{ pagination.total }} consultas SCR
        encontradas {{ (this.filtroDocumento ? `para o documento
        ${this.filtroDocumento}` : '') }}</p>

    </div>

  </div>
</template>

<script>
import Dashboard from '@/services/Partner/Dashboard'
import { VuetablePagination } from 'vuetable-2'

export default {
  components: { VuetablePagination },

  data: () => ({
    filtroDocumento: null,
    doc: "",
    selectedYear: new Date().getFullYear(),
    loading: false,
    months: Array.from({ length: 12 }, (_, index) => index + 1), // Meses como números
    years: Array.from(
      { length: new Date().getFullYear() - 2017 },
      (_, index) => 2018 + index
    ),
    selectedMonth: null,
    consultasSCR: [],
    header: [
      {
        name: '__slot:data',
        title: 'Data',
        width: '20%'
      },
      {
        name: '__slot:document',
        title: 'Documento',
        width: '40%'
      },
      {
        name: '__slot:database',
        title: 'Data-base',
        width: '20%'
      },
      {
        name: '__slot:link',
        title: '',
        width: '20%'
      },
    ],
    pagination: {
      current_page: 0,
      last_page: 0,
      per_page: 15,
      total: 0
    },

    baixandoConsultas: [],
    loadingConsultas: false,
    paginationCss: {
      wrapperClass: 'border rounded-lg flex m-2 text-secondary',
      activeClass: 'text-primary shadow-inner bg-gray-100 font-bold',
      disabledClass: 'text-gray-300 cursor-not-allowed',
      pageClass: 'pagination_page_class',
      linkClass: 'pagination_page_class',
      icons: {
        first: 'fas fa-angle-double-left',
        prev: 'fas fa-angle-left',
        next: 'fas fa-angle-right',
        last: 'fas fa-angle-double-right'
      }
    }
  }),
  computed: {
    maskPlaceholder() {
      return this.doc.length <= 11
        ? "###.###.###-##"
        : "##.###.###/####-##";
    },
    availableMonths() {
      const currentMonth = new Date().getMonth();
      return this.months.slice(0, currentMonth - 1);
    },
  },
  async mounted() {
    this.selectedMonth = this.availableMonths[this.availableMonths.length - 1]
    await this.getConsultas(1);
  },
  methods: {
    dataManager(sortOrder, pagination) {
      if (this.consultasSCR.length < 1) return
      return {
        pagination: this.pagination,
        data: this.consultasSCR
      }
    },
    onChangePage(page) {
      switch (true) {
        case page === 'next':
          return this.getConsultas(this.pagination.current_page + 1)
        case page === 'prev':
          return this.getConsultas(this.pagination.current_page - 1)
        default:
          return this.getConsultas(page)
      }
    },
    async visualizarSCR(id) {
      this.baixandoConsultas.push(id)
      try {
        let response;
        response = await Dashboard.consultaSCRId(id);

        const blob = new Blob([response.data], { type: 'application/pdf' }); // Forçar o tipo MIME para PDF
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.loading = false
        this.$store.dispatch('notificationErrors', error.response.data.erros)
        if (error.response.data.erros) {
          Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo, msg: erro }))
        }
      } finally {
        this.baixandoConsultas.splice(this.baixandoConsultas.indexOf(id), 1);
      }
    },
    async getConsultas(page) {
      if (page === this.pagination.current_page || page < 1 || (page != 1 && page > this.pagination.last_page)) return
      if (isNaN(page)) {
        page = 1
      }
      try {
        const perPage = 10;
        this.loadingConsultas = true
        this.$refs.vuetable && this.$refs.vuetable.resetData()
        this.consultasSCR = []
        const response = (await Dashboard.consultasSCR(page, perPage, this.filtroDocumento)).data.data
        this.consultasSCR = response.itens
        this.pagination = {
          current_page: page || 1,
          last_page: response.paginas,
          total: response.total,
          per_page: perPage
        }

        console.log(this.pagination)
        this.$refs.vuetable && this.$refs.vuetable.reload()
        this.$refs.pagination.setPaginationData(this.pagination)

      } catch (error) {
        console.error(error)
      } finally {
        this.loadingConsultas = false
      }
    },
    formatDocument(document) {
      const len = document.length;

      if (len === 11) { // CPF
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }

      if (len === 14) { // CNPJ
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }

      return document;
    },

    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const formattedDate = date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
      const formattedTime = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
      return `${formattedDate} ${formattedTime}`;
    },
    applyMask() {
      let value = this.doc.replace(/\D/g, "");
      if (value.length <= 11) {
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      } else {
        value = value.replace(/^(\d{2})(\d)/, "$1.$2");
        value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
        value = value.replace(/(\d{4})(\d)/, "$1-$2");
      }
      this.doc = value;
    },
    async consultSCR() {
      this.loading = true;
      try {
        let response;
        if (this.doc.replace(/\D/g, "").length <= 11) {
          response = await Dashboard.consultaSCRCpf(this.doc, this.selectedMonth, this.selectedYear);
        } else {
          response = await Dashboard.consultaSCRCnpj(this.doc, this.selectedMonth, this.selectedYear);
        }
        const blob = new Blob([response.data], { type: 'application/pdf' }); // Forçar o tipo MIME para PDF
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.loading = false
        this.$store.dispatch('notificationErrors', error.response.data.erros)
        if (error.response.data.erros) {
          Object.entries(error.response.data.erros).forEach(([campo, erro]) => this.errors.add({ field: campo, msg: erro }))
        }
      } finally {
        this.loading = false;

        this.getConsultas(1);
      }
    }

  },
};
</script>

<style lang="sass" scoped>
.main-content
  @apply w-full h-full bg-white border shadow rounded-lg

.title
  @apply text-gray-700 text-sm text-center font-bold mb-2 ml-4
  @screen lg
    @apply text-left text-base

</style>

<style lang="sass">
.proposals
  @apply w-full text-sm
  thead tr th
    @apply p-4 text-xs text-gray-600
    background: #ECECEC
  tr
    @apply border-b border-gray-300
    &:nth-child(even)
      @apply bg-gray-100
    &:not(.text-red-600)
      @apply text-green-600
    &:hover
      @apply bg-gray-200
  td
    @apply px-4 py-2 text-center whitespace-normal
    &:first-child
      @apply w-104

.pagination_page_class
  @apply h-8 w-8 border-l flex justify-center items-center cursor-pointer
  &:first-of-type
    @apply border-l-0 rounded-l-lg
  &:last-of-type
    @apply rounded-r-lg
  &:hover
    @apply bg-gray-100
    &.cursor-not-allowed
      @apply bg-transparent

      
</style>
