<template>
    <div class="flex flex-col pb-8 lg:flex-row lg:flex-wrap w-full my-2">
        <div class="w-full flex items-center mt-4">
            <p class="title">Consulta SCR</p>
            <br />
        </div>
        <consulta-scr />

    </div>
</template>

<script>
import consultaScr from "@/components/Partner/SCR/SCR";

export default {
    name: "fechamentos-view",
    components: { consultaScr },

};
</script>

<style lang="sass" scoped>

.title
  @apply font-bold text-xl text-secondary
  @screen lg
    @apply text-2xl
</style>
